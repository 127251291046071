<template>
  <v-menu>
    <!-- Start template for activating menu -->
    <template v-slot:activator="{ on }">
      <v-btn class="btn-icon-mobile" icon v-on="on">
        <v-icon color="primary"> mdi-dots-vertical </v-icon>
      </v-btn>
    </template>
    <!-- End template for activating menu -->
    <!-- Start menu wrapper -->
    <v-card color="primary" dark>
      <!-- Start list of items -->
      <v-list color="transparent">
        <!-- Start item iteratio -->
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.href">
          <!-- Start item icon -->
          <v-icon style="font-size: 14px; margin-right: 10px"
            >{{ item.icon }}
          </v-icon>
          <!-- End item icon -->
          <!-- Start item text -->
          <v-list-item-title
            v-text="item.title"
            :style="'color:white; font-size: 14px'"
          />
          <!-- Ed item text -->
        </v-list-item>
        <!-- End item iteration-->
        <!-- Start static options -->
        <v-list-item @click="logout">
          <!-- Start logout option -->
          <v-list-item-title
            v-text="'Cerrar sesión'"
            :style="'color:white; font-size: 14px'"
          />
          <!-- End logout option -->
          <!-- Start option icon -->
          <v-icon style="font-size: 14px; margin-right: 10px">
            mdi-logout
          </v-icon>
          <!-- End option icon-->
        </v-list-item>
        <!-- Ens static optioos -->
      </v-list>
      <!-- End list of items -->
    </v-card>
    <!-- End menu wrapper -->
  </v-menu>
</template>
<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then((resp) => {
          this.$store.commit("toggle_alert", resp);
          this.$router.go();
        })
        .catch((err) => {
          this.$store.commit("toggle_alert", err);
        });
    },
  },
  props: {
    items: Array,
  },
};
</script>
<style>
.btn-icon-mobile {
  margin-left: 0px !important;
  margin-top: 10px !important;
}
</style>